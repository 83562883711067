<template>
  <div class="app-input-container">
    <div class="app-input-group-container">
      <img v-if="!innactiveManualAttention" src="../assets/icons/emoji.svg" @click="openEmojiPicker()">
      <div v-if="showFileModal" class="file-modal">
      <div class="modal-content">
        <img src="../assets/icons/doc.png" />
        <p class="name-file">{{ uploadedFileName }}</p>
        <div class="modal-button-container">
        <button class="app-template-button-style" @click="cancelFileData">Cancelar</button>
        <button class="app-template-button" @click="sendFileData">Enviar</button>
        </div>
      </div>
    </div>
    <input type="file" ref="fileInput" style="display: none" @keyup.enter="handleFileUploadEnter" @change="handleFileUpload">
    <input v-if="!innactiveManualAttention" class="app-input" v-model="inputText" :disabled="isInputDisabled" @keyup.enter="sendMessageOnEnter">
    <img src="../assets/icons/library.png" @click="showTemplateContainer" v-if="isInputDisabled && !innactiveManualAttention" />
      <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .png, .mp4, .jpg">
      <img v-if="!innactiveManualAttention" src="../assets/icons/text.png" @click="openFileUploader">
      <img v-if="!innactiveManualAttention" src="../assets/icons/send.png" @click="sendMessage">
    </div>
    <div class="app-emojipicker-container" v-if="showEmojiPicker">
      <EmojiPicker :native="true" @select="onSelectEmoji" :disable-skin-tones="true" />
    </div>
    <div class="app-templete-container" :class="{minimized: isTemplateContainerMinimized, hidden: !isTemplateContainerVisible }">
      <div class="app-template-tags-container">
      <p class="app-template-title">Seleccionar plantilla</p>
      <select v-model="selectedTemplate" @change="countTags(selectedTemplate)" v-if="isInputDisabled" class="app-template-input">
        <option value="" selected disabled>Seleccionar Plantilla</option>
      <option v-for="(template, index) in props.chatInformation?.templates" :key="index" :value="template.message_template">
      {{ template.name }}
      </option>
      </select>
      </div>
      <div v-if="selectedTemplate" class="app-template-tags-container">
        <p class="app-template-title">Cuerpo del mensaje</p>
        <textarea v-if="selectedTemplate" class="app-template-textArea" v-model="selectedTemplate"></textarea>
      </div>
      <div class="app-template-scroll">
      <div class="app-template-tags-container" v-if="tags.length > 0">
        <p class="app-template-title">Reemplazo de etiquetas</p>
          <div class="app-template-tags-input">
            <div v-for="(tag, index) in tags" :key="index" class="app-template-tag">
            <input class="app-template-input" style="background-color: #E7E7E9;" type="text" :value="tag.index" disabled>
            <input class="app-template-input" type="text" v-model="tag.tagValue">
          </div>
        </div>
      </div>
      </div>
      <div v-if="showSendButton" style="display: flex; justify-content: flex-end; padding-top: inherit;">
        <button class="app-template-button" @click="validateTemplate">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onUpdated } from 'vue';
import EmojiPicker from 'vue3-emoji-picker';
import servicesSendMessage from '../services/ServicesSendMessage';
import {COMPANY} from '../../config';

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const messages = ref([]);
const showEmojiPicker = ref(false);
const inputText = ref('');
const sendMessageResponse = ref(null);
const selectedTemplate = ref('');
const isInputDisabled = ref(true);
const isWithin24Hours = ref(false);
const tags = ref([]);
const isTemplateContainerVisible = ref(true);
const isTemplateContainerMinimized = ref(true);
const showSendButton = ref(false)
const fileInput = ref(null);
const fileThumbnail = ref(null);
const showFileModal = ref(false);
const uploadedFileName = ref(null);
const emits = defineEmits({messageSent: String});

const innactiveManualAttention = ref(false)

const props = defineProps({
chatInformation: {
  type: Object,
  required: true
},
lastMessage: {
  type: Object,
  required: true
}
})

const updateInnactiveManualAttention = () => {
  let receivedValue = false;

  if (props.chatInformation && props.chatInformation.innactiveManualAttention !== undefined) {
    receivedValue = props.chatInformation.innactiveManualAttention;
    innactiveManualAttention.value = receivedValue;
  } else {
    innactiveManualAttention.value = false;
  }

  if (receivedValue === true) {
    toast.error('El usuario está siendo atendido', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

updateInnactiveManualAttention();

onUpdated(() => {
  updateInnactiveManualAttention();
});

function sendMessageOnEnter() {
  const textToSend = inputText.value;
  if (typeof textToSend === 'string' && textToSend.trim() !== '') {
    sendMessage();
  }
}

function openEmojiPicker() {
  showEmojiPicker.value = !showEmojiPicker.value;
}

function onSelectEmoji(emoji) {
    showEmojiPicker.value = false;
    inputText.value = `${inputText.value}${emoji.i}`;
}

function countTags() {
  const template = selectedTemplate.value;
  const regex = /{{(?:\b|-)([1-9]{1,2}[0]?|10)\b}}/g;
  const matches = template.match(regex);
  if (matches) {
    tags.value = matches.map((match) => {
      return { index: match, tagValue: '' };
    });
  } else {
    tags.value = [];
  }
  showSendButton.value = true;
}

function validateTemplate() {
  let isValid = true;
  for (let index = 0; index < tags.value.length; index++) {
    const tag = tags.value[index];
    if (!tag.tagValue) {
      isValid = false;
    }
  }
  if (isValid) {
    sendMessage();
    showTemplateContainer();
  } else {
    toast.warn('Falta rellenar campos', {
    position: toast.POSITION.TOP_CENTER,
  })
  }
}

function showTemplateContainer() {
  if (isTemplateContainerVisible.value) {
    isTemplateContainerVisible.value = false;
    isTemplateContainerMinimized.value = true;
  } else {
    isTemplateContainerVisible.value = true;
    isTemplateContainerMinimized.value = false;
  }
}

function checkIf24HoursPassed() {
  if (props.lastMessage && props.lastMessage.creation_date) {
    const currentDateTime = new Date();
    const creationDate = new Date(props.lastMessage.creation_date);
    const hoursDifference = (currentDateTime - creationDate) / (1000 * 60 * 60);
    isInputDisabled.value = hoursDifference >= 24;
    return isInputDisabled.value;
  } else {
    return false;
  }
}
onUpdated(() => {
  checkIf24HoursPassed();
})

async function sendMessage() {
  if (!isWithin24Hours.value) {
    if (isInputDisabled.value) {
      if (selectedTemplate.value !== null) {
        const selectedTemplateName = props.chatInformation.templates.find(
          (template) => template.message_template === selectedTemplate.value
        ).name;
        const text_tags = tags.value.map((tag) => tag.tagValue);
        const message = {
          company: props.chatInformation.company,
          userId: props.chatInformation.executiveUserData.id,
          channel: props.chatInformation.channel,
          chat_user: props.chatInformation.chatUser,
          message: {
            template: selectedTemplateName,
            text_tags: text_tags,
          }
        };

        try {
          sendMessageResponse.value = await servicesSendMessage.postDataSendMessage(
            message
          );
          if (sendMessageResponse.value.status === 424) {
            
            isWithin24Hours.value = true;
            isInputDisabled.value = false;
          } else {
            toast.success('template Enviado', {
              position: toast.POSITION.TOP_CENTER,
            }, sendMessageResponse.value.chatConversationId);
              const sentMessage = {
              id: messages.value.length + 1,
              author: 'executive',
              text: selectedTemplateName,
              date: '11:31 AM',
              };
            messages.value.push(sentMessage);
            inputText.value = '';
            emits('messageSent');
          }
        } catch (error) {
          toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          })
        }
      } else {
        toast.warn('Selecciona un template antes de enviar el mensaje.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      if (inputText.value.trim() !== '') {
        const textToSend = inputText.value;
        const message = {
          company: props.chatInformation.company,
          userId: props.chatInformation.executiveUserData.id,
          channel: props.chatInformation.channel,
          chat_user: props.chatInformation.chatUser,
          message: {
            type: "text",
            text: textToSend,
          }
        };
        try {
          sendMessageResponse.value = await servicesSendMessage.postDataSendMessage(
            message
          );
          const sentMessage = {
            id: messages.value.length + 1,
            author: 'executive',
            text: textToSend,
            date: '11:31 AM',
          };
          messages.value.push(sentMessage);
          inputText.value = '';
          emits('messageSent');
        } catch (error) {
          toast.error('Error al enviar el mensaje de texto', {
            position: toast.POSITION.TOP_CENTER,
          }, error);
        }
      } else {
        toast.warn('Ingresa texto antes de enviar el mensaje.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  } else {
    toast.success('Enviar mensaje', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
}


function openFileUploader() {
fileInput.value.click();
}

const currentFile = ref(null);

function handleFileUpload(event) {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      const fileTypeMapping = {
      'application/pdf': 'document',
      'application/msword': 'document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document',
      'application/vnd.ms-powerpoint': 'document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'document',
      'application/vnd.ms-excel': 'document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'document',
      'image/jpeg': 'image',
      'image/png': 'image',
      'video/mp4': 'video',
      };
      const fileType = fileTypeMapping[file.type] || 'other';
      const fileData = {
        company: props.chatInformation.company,
        userId: props.chatInformation.executiveUserData.id,
        channel: props.chatInformation.channel,
        chat_user: props.chatInformation.chatUser,
        message: {
          type: 'file',
          text: reader.result,
          file_type: fileType,
          file_name: file.name,
        }
      };
      fileThumbnail.value = reader.result;
      currentFile.value = fileData
    };
    uploadedFileName.value = file.name;
    showFileModal.value = true; 
    reader.readAsDataURL(file);
  }
}

function handleFileUploadEnter(event) {
  event.preventDefault();
}


async function sendFileData() {
  try {
    const response = await servicesSendMessage.postDataSendMessage(currentFile.value);
    toast.success('Archivo enviado con éxito',{
    position: toast.POSITION.TOP_CENTER,
    }, response)
    currentFile.value = null;
    uploadedFileName.value = null;
    showFileModal.value = false;
    emits('messageSent');
  } catch (error) {
    toast.error('Error al enviar el archivo',{
    position: toast.POSITION.TOP_CENTER,
    },error)
  }
}

function cancelFileData(){
  currentFile.value = null;
  showFileModal.value = false;
}

</script>

<style>
@import '../assets/vue3Emoji.css';
.app-input-container {
  position: relative;
}
.app-input-group-container {
  width: 100%;
  display: flex;
  gap: .8rem;
  padding: 1.2rem;
  border-top: 1px solid #0000001A;
  align-items: center;
}
.app-input, .app-input:focus, .app-input:active {
  flex-grow: 1;
  width: 100%;
  border: 0;
  outline: 0;
}

.app-emojipicker-container {
  position: absolute;
  top: -330px;
  left: 10px;
}

.app-templete-container{
  padding: 1.5rem;
  width: 80%;
    height: 75vh;
    top: -70vh;
    right: 15px;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 10px #0003;
    border-radius: 10px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.app-templete-container.minimized{
  overflow: hidden;
  display: none; 
}

.app-template-input{
  width: 100%;
  height: 44px;
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  border: 1px solid #D0D5DD
}

.app-template-tags-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
}

.app-template-scroll {
  overflow-y: auto;
  padding-right: 10px;
}

.app-template-scroll::-webkit-scrollbar {
  width: 10px;
}

.app-template-scroll::-webkit-scrollbar-thumb {
  background-color: #9DE4D0;
  border-radius: 5px;
}

.app-template-scroll::-webkit-scrollbar-track {
  background-color: #E7E7E9;
  border-radius: 5px;
}

.app-template-scroll::-webkit-scrollbar-button {
  display: none;
}

.app-template-tags-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.app-template-tag {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.app-template-title{
  color: #2C2C2E;
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.app-template-text{
  width: 100%;
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  color: #697279;
  background-color: #E7E7E9;
}

.app-template-textArea {
  width: 100%;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  color: #697279;
  background-color: #E7E7E9;
  resize: vertical;
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
}

.app-template-button{
  background-color: #9DE4D0;
  width: 82px;
  padding: 8px 14px 8px 14px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
}

.app-template-button-style {
    width: 82px;
    padding: 8px 14px 8px 14px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
}

.file-thumbnail {
  max-width: 100px;
  max-height: 100px;
  margin-top: -35px;
}

.file-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ddd;
}

.file-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-button-container{
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.name-file{
  word-wrap: break-word;
}

@media screen and (max-width: 700px) {
  .modal-content{
    width: 85%;
  }
  .app-template-button{
    margin: 0px 0px 0px 0px;
  }

  .app-templete-container {
    overflow-y: auto;
    width: 90%;
    max-height: 70vh;
    right: 0;
    left: 12px;
    height: 75vh;
    top: -70vh;
  }
  
}

</style>