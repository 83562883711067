import { TOKEN, apiUrl } from '../../config';
const endpoint = 'sendMessage';
const apiUrlWithEndpoint = apiUrl(endpoint);

export default {
    async postDataSendMessage(data) {
        try {
            const response = await fetch(apiUrlWithEndpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': TOKEN,
              },
              body: JSON.stringify(data),
            });
            
            if (!response.ok) {
              const errorCodeMessages = {
                100: 'En la solicitud, se incluyeron uno o más parámetros no admitidos o mal escritos.',
                131026: 'El número de teléfono del destinatario no es un número de teléfono de WhatsApp.',
                131021: 'El número de teléfono del emisor y el del destinatario son el mismo.',
                131016: 'Un servicio no está disponible temporalmente.',
                131009: 'Uno o más valores del parámetro no son válidos.',
                131008: 'Falta un parámetro obligatorio en la solicitud.',
                131051: 'Tipo de mensaje no compatible.',
              };
              const responseDataError = await response.json();
              const errorCode = responseDataError.error.details.errorCode
              const errorMessage = errorCodeMessages[errorCode] || `Error al enviar el mensaje${errorCode}`
              throw new Error(errorMessage);
            }
          
            const responseData = await response.json();
            return responseData;
          } catch (error) {
            console.error('Error al realizar la solicitud:', error);
            throw error;
          }
    }
}