import { useCookies } from "vue3-cookies";
import VueJwtDecode from "vue-jwt-decode";
import { useStore } from "vuex";

export default class AzureAdB2C {
  constructor(policy) {
    const {
      VUE_APP_HOST_B2C_AUTHORIZE,
      VUE_APP_HOST_B2C_CLIENT_ID,
      VUE_APP_HOST_B2C_REDIRECT_URI,
      VUE_APP_HOST_B2C_REDIRECT_URI_NAMMI,
    } = process.env;

    const currentOrigin = window.location.origin;

    let redirect_uri = VUE_APP_HOST_B2C_REDIRECT_URI;
    if (currentOrigin.includes("nammichile")) {
      redirect_uri = VUE_APP_HOST_B2C_REDIRECT_URI_NAMMI;
    }

    this.baseURL = VUE_APP_HOST_B2C_AUTHORIZE;
    //this.tokenURL = `https://admssdev.b2clogin.com/admssdev.onmicrosoft.com/${policy}/oauth2/v2.0/token`; // QA
    this.tokenURL = `https://authindumotora.b2clogin.com/authindumotora.onmicrosoft.com/${policy}/oauth2/v2.0/token`; //PROD;
    this.client_id = VUE_APP_HOST_B2C_CLIENT_ID;
    this.nonce = "defaultNonce";
    this.redirect_uri = encodeURIComponent(redirect_uri);
    this.scope = encodeURIComponent("openid");
    this.response_type = encodeURIComponent("code");
    this.prompt = "login";
    this.policy = policy;
    this.code_verifier = this.generateCodeVerifier();
    this.cookies = useCookies();
    this.store = useStore();
  }

  generateCodeVerifier() {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    if (
      localStorage.getItem("code_verifier") != null &&
      localStorage.getItem("code_verifier") !== undefined
    ) {
      return localStorage.getItem("code_verifier");
    }
    let buffer = this.arrayBufferToBase64Url(array);
    localStorage.setItem("code_verifier", buffer);
    return buffer;
  }

  async generateCodeChallenge(verifier) {
    if (
      localStorage.getItem("challenge") != null &&
      localStorage.getItem("challenge") !== undefined
    ) {
      return localStorage.getItem("challenge");
    }
    const challenge = await crypto.subtle
      .digest("SHA-256", new TextEncoder().encode(verifier))
      .then((buffer) => this.arrayBufferToBase64Url(buffer));
    localStorage.setItem("challenge", challenge);
    return challenge;
  }

  arrayBufferToBase64Url(arrayBuffer) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }

  async buildUrl() {
    this.code_challenge = await this.generateCodeChallenge(this.code_verifier);
    return `${this.baseURL}?p=${this.policy}&client_id=${this.client_id}&nonce=${this.nonce}&redirect_uri=${this.redirect_uri}&scope=${this.scope}&response_type=${this.response_type}&prompt=${this.prompt}&code_challenge=${this.code_challenge}&code_challenge_method=S256`;
  }

  async getTokenUsingAuthorizationCode(code) {
    if (!code) return;
    const tokenRequestBody = new URLSearchParams();
    tokenRequestBody.append("client_id", this.client_id);
    tokenRequestBody.append("scope", "openid"); // Asegúrate de incluir otros scopes necesarios
    tokenRequestBody.append("code", code);
    tokenRequestBody.append(
      "redirect_uri",
      decodeURIComponent(this.redirect_uri)
    );
    tokenRequestBody.append("grant_type", "authorization_code");
    //tokenRequestBody.append("client_secret", "YOUR_CLIENT_SECRET"); // Solo si es necesario
    tokenRequestBody.append("code_verifier", this.code_verifier);

    await fetch(this.tokenURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: tokenRequestBody,
    })
      .then((response) => response.json())
      .then(async (data) => {
        this.cookies.cookies.set("token", data);
        var decodeJwt = VueJwtDecode.decode(data.id_token);
        if (!decodeJwt.jobTitle) return false;
        this.store.commit({
          type: "updatetoggleIdUser",
          value: decodeJwt.jobTitle,
        });
        await this.store.dispatch("loginUser", decodeJwt.jobTitle);
      })
      .catch((error) => {
        console.error("Error obtaining tokens:", error);
      });
  }
  async refreshIdToken() {
    const tokenRequestBody = new URLSearchParams();
    const token = this.cookies.cookies.get("token");
    tokenRequestBody.append("client_id", this.client_id);
    tokenRequestBody.append("scope", "openid");
    tokenRequestBody.append("refresh_token", token.refresh_token);
    tokenRequestBody.append("grant_type", "refresh_token");

    try {
      const response = await fetch(this.tokenURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: tokenRequestBody,
      });
      const data = await response.json();
      this.cookies.cookies.set("token", data);
    } catch (error) {
      console.error("Error refreshing tokens:", error);
    }
  }
}
