<script setup>
import {ref, onMounted, onBeforeUnmount, computed, defineProps, nextTick} from 'vue';
import Input from './Input.vue';
import Message from './Message.vue';
import conversationsService from '../services/ServicesConversations';
import endConversationsServices from '../services/ServicesEndConversation'
import dayjs from 'dayjs'
import { PHONE, COMPANY, EVENT_SOURCE_URL, AUTH } from '../../config';

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


const props = defineProps({
  chatInformation: {
    type: Object,
    required: true
  }
})
const client = ref({});
const conversationsResponse = ref(null)
const conversations = ref([])
const minimized = ref(false);
const showConfirmationModal = ref(false);
const showChat = ref(true);
const activeConversationId = ref('');
const SSEActiveConversationId = ref('');
const SSEClientConnetion = ref('');
const lastMessage = ref(null);

const idSSE = props.chatInformation.chatUser

let pollingInterval;

const loadMessages = async () => {
  try {
    conversationsResponse.value = await conversationsService.getConversations({
      phone: PHONE,
      company: props.chatInformation.company,
      channel: props.chatInformation.channel,
      chatUser: props.chatInformation.chatUser,
      executive: props.chatInformation.executiveUserData.id
    });
    if (conversationsResponse.value) {
      client.value = conversationsResponse.value.description.chatUser;
      extraActiveConversationId(conversationsResponse.value.description.conversations);

      for (let index = 0; index < conversationsResponse.value.description.conversations.length; index++) {
        const conversation = conversationsResponse.value.description.conversations[index];
        if (Array.isArray(conversation.messages)) {
          let auxMessages = [];
          for (let innerIndex = 0; innerIndex < conversation.messages.length; innerIndex++) {
            const message = conversation.messages[innerIndex];
            if (message.from_type === 'chat_user') {
              auxMessages.push({
                author: 'client',
                name: client.value?.client_data?.nombreCliente || client.value.first_name || 'Visita',
                message: message.input,
                date: dayjs(message.creation_date).format('HH:mm A'),
                file: message.file || null,
              });
              lastMessage.value = message
              

              if (Array.isArray(message.response_object)) {
              message.response_object.forEach(botMessage => {
              auxMessages.push({
              author: 'bot',
              name: conversation.channel.plugin_asistente_name || 'Bot', 
              message: botMessage,
              date: dayjs(message.creation_date).format('HH:mm A'),
              file: null,
              });
              });
            }
            } else {
              auxMessages.push({
                author: 'executive',
                name: message.manual_conversation_user_name || 'Ejecutivo',
                message: message.file ? '': message.input,
                date: dayjs(message.creation_date).format('HH:mm A'),
                file: message.file || null,
              });
            }
          }
          conversation.messages = auxMessages;
          
        }
      }
      conversations.value = conversationsResponse.value.description.conversations;
    } else conversations.value = [];
    await nextTick();
    if (messageContainer.value) {
      messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
    }
  } catch (error) {
    toast.error('Error al cargar los mensajes',{
    position: toast.POSITION.TOP_CENTER,
    },error)
  }
};

const unreadMessageCount = ref(0);

function extraActiveConversationId(conversations){
  const result = conversations.find((conversation) => !conversation.end_reason && !conversation.end_date)
  if(result)  {
    activeConversationId.value = result._id
  }
  else activeConversationId.value = ''
}


const messageContainer = ref(null);


const toggleMinimize = () => {
  minimized.value = !minimized.value;
};

const toggleContent = () => {
  minimized.value = false;
  unreadMessageCount.value = 0;
};

const formatLastMessageTime = computed(() => {
  if (client.value.last_message) {
    const lastMessageTime = dayjs(client.value.last_message);
    const currentTime = dayjs();
    const minutesDifference = currentTime.diff(lastMessageTime, 'minute');
    
    if (minutesDifference < 60) {
      return `Último mensaje hace ${minutesDifference} minutos`;
    } else if (minutesDifference < 1440) { // 1440 minutos en 24 horas
      const hoursDifference = currentTime.diff(lastMessageTime, 'hour');
      return `Último mensaje hace ${hoursDifference} horas`;
    } else {
      const daysDifference = currentTime.diff(lastMessageTime, 'day');
      return `Último mensaje hace ${daysDifference} días`;
    }
  } else {
    return 'Sin información';
  }
});

const confirmCloseConversation = async () => {
  showConfirmationModal.value = false;
  await CloseConversation();
  await closeConversationSSE(SSEActiveConversationId.value, SSEClientConnetion.value);
  window.parent.postMessage({ type: 'conversationClosed' }, '*');
};

const cancelCloseConversation = () => {
  showConfirmationModal.value = false;
};

const CloseConversation = async () => {
  try {
    if (activeConversationId.value) {
      const data = {
        company: props.chatInformation.company,
        id: activeConversationId.value,
      };
      const response = await endConversationsServices.getEndConversations(data);
      if (response && response.success) {
        toast.success('Conversación cerrada con éxito', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error('Error al cerrar la conversación', {
          position: toast.POSITION.TOP_CENTER,
        }, response.error);
      }
    }
  } catch (error) {
    toast.error('Error al cerrar la conversación', {
      position: toast.POSITION.TOP_CENTER,
    }, error);
  } finally {
    showChat.value = false;
  }
};


function startSSE() {
  const headers = {
    'Authorization': AUTH,
    'Connection': 'keep-alive'
  };

  fetch(`${EVENT_SOURCE_URL}${idSSE}`, { 
    method: 'GET',
    headers: headers,
  })
    .then(response => {
      if (response.ok) {
        const stream = response.body;
        const reader = stream.getReader();
        readStream(reader,idSSE);
      } else {
        toast.error('Fallo en conexión SSE, se reintentara en 60 seg',{
        position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          startSSE(idSSE);
        }, 60000);
      }
    })
    .catch(error => {
      console.error('Fetch error:', error);
      setTimeout(() => {
          startSSE(idSSE);
        }, 60000);
    });
}

 function readStream(reader) {
  reader.read().then(({ done, value }) => {
    if (done) {
      handleEndEvent(idSSE);
    } else {
      const jsonString = new TextDecoder('utf-8').decode(value);
      handleMessageEvent(jsonString, idSSE);
      readStream(reader, idSSE);
    }
  }).catch(error => {
    console.error('Error reading SSE stream:', error);
    startSSE(idSSE);
  });
}

function handleEndEvent() {
  startSSE(idSSE);
}

function isJson (txt) {
  let is_json = true
  try {
    JSON.parse(txt)
  } catch (e) {
    is_json = false
  }
  return is_json
}

function handleMessageEvent(message) {
  if (message) {
    try {
      let data = message.replace('data: ', '').trim();
      data =isJson(data)?JSON.parse(data):undefined;
      if (data?.clientId) {
        const idClientSingleChat = data.clientId;
        SSEActiveConversationId.value = idSSE;
        SSEClientConnetion.value = idClientSingleChat
      }
      loadMessages();
      if (minimized.value && message.author !== 'client') {
      unreadMessageCount.value += 1;
      }
    } catch (error) {
      console.error('Error parsing SSE message:', error);
    }
  }
}


async function closeConversationSSE(idConversation,idClientConection) {

  const headers = {
    'Authorization': AUTH,
  };

  const data = {
    clientId: idClientConection,
  }

  fetch(`${EVENT_SOURCE_URL}closeConexion/${idSSE}`, { 
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  })
    .then(response => {
    })
    .catch(error => {
      toast.error('Error al cerrar conversacion',{
        position: toast.POSITION.TOP_CENTER,
        },error)
      throw error;
    });
  
}


onMounted(async () => {
  await loadMessages();
  startSSE(activeConversationId.value);
});

const handleSendMessage = () => {
  loadMessages();
} 


onBeforeUnmount(() => {
  clearInterval(pollingInterval);
});

async function listengTest(conversationId){
 if(!SSEActiveConversationId.value){
  startSSE(idSSE);
 } else if(SSEActiveConversationId.value !== conversationId){
  startSSE(idSSE);
  await closeConversationSSE(SSEActiveConversationId.value, SSEClientConnetion.value);
 }
}

</script>


<template>
  <div class="app-container" v-if="showChat">
    <div class="app-chat-container" :style="{ display: minimized ? 'none' : 'block' }">
      <div class="app-chat-container-header">
        <div>
          <img src="../assets/icons/cerrar.png" @click="showConfirmationModal = true">
          </div>
          <div class="confirmation-modal" v-if="showConfirmationModal">
          <div class="modal-content">
            <div class="modal-body">
            <img src="../assets/icons/cierre.png" alt="">
            <p>¿Estás seguro de que deseas cerrar la conversación?</p>
            </div>
           <div class="modal-button-container">
          <button class="app-template-button-style" @click="cancelCloseConversation">Cancelar</button>
          <button class="app-template-button" @click="confirmCloseConversation">Confirmar</button>
          </div>
          </div>
          </div>
          <div class="app-content-header">
          <p class="app-client-header">{{ client?.client_data?.nombreCliente || client.first_name || 'Visita' }}</p>
          <p>{{ formatLastMessageTime }}
          </p>
        </div>
        
      </div>
      <div class="app-chat-container-body" ref="messageContainer">
        <div v-for="(conversation, index) in conversations" :key="index">
          <p v-if="conversation.messages.length > 0" class="app-chat-container-conversation-date"> {{ dayjs(conversation.creation_date).format('DD/MM/YYYY') }}</p>
          <div class="app-chat-conversation-messages-container">
            <div v-for="(message, index) in conversation.messages" :key="index">
              <Message :data="message"></Message>
            </div>
          </div>
        </div>
      </div>
      <div class="app-chat-container-footer">
        <Input @messageSent="handleSendMessage" :chatInformation="props.chatInformation" :lastMessage="lastMessage" @receptConversationId="listengTest($event)"></Input>
      </div>
    </div>
    <div class="user-button" v-if="minimized" @click="toggleContent">
      <div class="app-user-message-notification">{{ unreadMessageCount }}</div>
      {{ client?.client_data?.nombreCliente || client.first_name || 'Visita' }}
    </div>
  </div>
</template>

<style lang="css" scoped>
.app-container {
  min-height: 100vh;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.app-chat-container {
  border: 1px solid #0000001A;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app-chat-container-header {
  display: flex;
  align-items: center;
  padding: .5rem 1.2rem;
  width: 100%;
  border-bottom: 1px solid #0000001A;
}

.app-content-header {
  flex-grow: 1;
  text-align: center;
}

.app-client-header {
  font-weight: 800;
}

.app-chat-container-body {
  display: flex;
  flex-direction: column;
  gap: .8rem;
  padding: .8rem 1.5rem;
  flex-grow: 1;
  max-height: 80vh;
  overflow: auto;
  height: 80vh;
}

.app-chat-container-conversation-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  padding: .8rem
}

.app-chat-conversation-messages-container {
  display: flex;
  flex-direction: column;
  gap: .8rem;
  flex-grow: 1;
}

.modal-button-container{
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

@media screen and (max-width: 500px) {
  .app-content-header {
    width: 85%;
  }
}

.minimize-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.minimize-button.minimized {
  transform: rotate(180deg);
}

.user-button {
  width: 150px;
  height: 40px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-user-message-notification {
    position: absolute;
    top: 0px;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 700;
    margin-left: 145px;
}


.user-button:hover {
  background-color: #e0e0e0;
}

.user-button.minimized {
  background-color: #f44336;
  border-color: #f44336;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-button.minimized:hover {
  background-color: #d32f2f;
  border-color: #d32f2f;
}

.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .modal-content {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  align-items: center;
}

.modal-body{
  display: flex;
  gap: 1rem;
  align-items: center;
}

  

  .app-template-button {
    background-color: #9DE4D0;
    width: 82px;
    padding: 8px 14px 8px 14px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
}

.app-template-button-style {
    width: 82px;
    padding: 8px 14px 8px 14px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease; /* Ajusta la duración y el efecto de transición según tus preferencias. */
}

.fade-in.active {
  opacity: 1;
}

</style>